import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import API from '../api/api';
import { Text, Title, Button, Box } from '../components/Core';
import Section from '../components/Section';
import { Container, Col, Row } from 'react-bootstrap';
import { getProperty } from '../utils/helperFn';
import { navigate } from 'gatsby';
import GlobalContext from '../context/GlobalContext';
import FullScreenLoader from '../components/FullScreenLoader';
import { AgreementSwitch } from '../components/Core/Switch';
import Loader from '../assets/image/gif/loader.gif';
import TimeSlotPicker from '../sections/CheckoutSteps/StepThree/TimeSlotPicker';
import { Form, Formik } from 'formik';
import { isMobile } from 'react-device-detect';
import BigRoundButton from '../components/BigRoundButton';
import { Modal } from "react-bootstrap";
import { TinySwitch } from '../components/Core/Switch';

const AppointmentsPage = (props) => { 
  const gContext = useContext(GlobalContext);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [addressId, setAddressId] = useState(null);
  let registrationData = gContext.goGetRegistrationData();
  const [dateFilter, setDateFilter] = useState('EARLIEST');
  const [firstDate, setFirstDate] = useState(null);
  const [initialDate, setInitialDate] = useState('');
  const [initialTime, setInitialTime] = useState('');
  const [showNoSlotsModal, setShowNoSlotsModal] = useState(false);
  const [calendarData, setCalendarData] = useState(null);
  const [earlierAvailability, setEarlierAvailability] = useState(false);
  const [loaderTitle, setLoaderTitle] = useState('');

  const customDate = registrationData?.Date || gContext?.goGetRegistrationData()?.Date || undefined;
  const customTime = registrationData?.Time || gContext?.goGetRegistrationData()?.Time || undefined;

  let woId = '';

  useEffect(() => {
    const orderId = new URLSearchParams(props.location.search).get('orderid');

    if (!orderId) {
      navigate('/');
    } else {
      setLoaderTitle('Loading your order details...');
      gContext.showFullScreenLoader();
      getOrder(orderId);
    }
  }, []);

  useEffect(() => {
    const fetchDataForDateRange = async (startDate) => {  
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
      try {
        const endDate = formatDate(addDaysToDate(startDate, 30));
        const response = await API.get(
          `/FieldServiceModule/v1.0/ServiceAppointment/calendar?start=${startDate}&end=${endDate}&addressId=${addressId}`
        );

        return response.data.data;
      } catch (error) {
        throw error;
      }
    };

    const currentDate = moment(new Date()).format('YYYY-MM-DD');
    const nextDate = moment(currentDate).add(30, 'days').format('YYYY-MM-DD');

    gContext.setQuoteLoading(true);
    setLoaderTitle('Loading available install slots, please wait...');
    gContext.showFullScreenLoader();

    if (addressId) {
      Promise.all([fetchDataForDateRange(currentDate), fetchDataForDateRange(nextDate)])
      .then(([currentData, nextData]) => {
        const mergedCalendarData = {};

        [currentData, nextData].forEach((data) => {
          data.forEach((record) => {
            const date = record.Date;
            if (!mergedCalendarData[date]) {
              mergedCalendarData[date] = {
                AM: record.AM,
                PM: record.PM,
                AMConfigId: record.AM ? record.AMConfig.id : undefined,
                PMConfigId: record.PM ? record.PMConfig.id : undefined,
                AmOptimizer: record.AM ? record.AMConfig.optimizer : undefined,
                PmOptimizer: record.PM ? record.PMConfig.optimizer : undefined,
              };
            }
          });
        });

        setCalendarData(mergedCalendarData);

        let firstDate;
        Object.keys(mergedCalendarData).some((date) => {
          const record = mergedCalendarData[date];
          if (record.AM || record.PM) {
            firstDate = { ...record, Date: date };
            return true;
          }
          return false;
        });

        if (firstDate) {
          const firstTime = firstDate.AM ? 'AM' : 'PM';
          setFirstDate(firstDate);
          setInitialDate(moment(new Date(firstDate.Date)).format('DD MMM YYYY'));
          setInitialTime(firstTime);

          gContext.goSetRegistrationData({
            ...gContext.goGetRegistrationData(),
            calendarData: mergedCalendarData,
            dateFilter: dateFilter,
            Date: firstDate.Date,
            Time: firstTime,
            firstDate: firstDate,
          });

        } else {
          setShowNoSlotsModal(true);
        }
        
        gContext.setQuoteLoading(false);
        gContext.hideFullScreenLoader();
        setLoaderTitle('');
      })
      .catch((error) => {
        setShowNoSlotsModal(true);
        gContext.hideFullScreenLoader();
        setLoaderTitle('');
        gContext.setQuoteLoading(false);
      });
    }
  }, [addressId]);

  const getOrder = async (orderId) => {
    if (orderId) {
      const order = await API.get(
        `OrderModule/v1.0/db/Order/${orderId}?entities=["WorkOrder"]&withLinks=true`,
      );

      let addressID = '';
      const address = order.data?.data?.links?.find(
        (associatedRecord) => associatedRecord.entity === 'CrmModule:Address',
      );

      /* ODN-2292 - Use registrationData address id if the one from the API is not available. Or fail graciously. */
      if (address && address.id) {
        setAddressId(address.id);
        addressID = address.id;
      } else if (!address && registrationData && registrationData.addressId) {
        setAddressId(registrationData?.addressId);
        addressID = registrationData?.addressId;
      } else {
        console.log(
          'No addressId provided by either order endpoint or application state / previous checkout data.',
        );
      }

      /* Appointment is already scheduled... */
      if (getProperty(order.data.data.WorkOrder, 'dbRecords')) {
        const woId = getProperty(order.data.data.WorkOrder, 'dbRecords')[0]['id'];

        const workOrder = await API.get(
          `FieldServiceModule/v1.0/db/WorkOrder/${woId}?entities=["ServiceAppointment"]`,
        );

        if (getProperty(workOrder.data.data.ServiceAppointment, 'dbRecords')) {
          gContext.hideFullScreenLoader();
          setLoaderTitle('');
          navigate(
            `/events/appointment-error?orderid=${orderId}`,
            {
              state: { registrationData },
            },
          );
        } else {
          gContext.hideFullScreenLoader();
          setLoaderTitle('');
        }
      } else {
        gContext.hideFullScreenLoader();
        setLoaderTitle('');
      }
      gContext.hideFullScreenLoader();
      setLoaderTitle('');
    }
  };

  const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const addDaysToDate = (startDate, days) => {
    if (typeof startDate === 'string') {
      startDate = moment(startDate).toDate();
    }
    return startDate.setDate(startDate.getDate() + days);
  };

  function submitAppointment(values) {
    setLoaderTitle('Submitting your appointment...');
    gContext.showFullScreenLoader();
    let orderId = new URLSearchParams(props.location.search).get('orderid');

    try {
      if (woId) {
        API.post(`FieldServiceModule/v1.0/ServiceAppointment/WorkOrder/${woId}/reserve`, {
          Date: registrationData.Date,
          TimeBlock: registrationData.Time,
          scheduleId: registrationData?.calendarData[registrationData?.Date][registrationData?.Time + 'ConfigId'],
          EarlierAvailability: earlierAvailability,
        })
          .then((resp) => {
            navigate(`/appointment-success?woId=${woId}/`);
            gContext.hideFullScreenLoader();
            setLoaderTitle('');
          })
          .catch((error) => {
            gContext.setAPIErrorMsg({
              title: 'Error',
              message: error.response ? error.response?.data.message : error
            });
            gContext.hideFullScreenLoader();
            setLoaderTitle('');
            navigate('/');
          });
      } else {
        API.post(`/FieldServiceModule/v1.0/WorkOrder/order/${orderId}`, {
          Type: 'INSTALL',
          skipCustomerNotification: true,          
          Date: registrationData?.Date,
          TimeBlock: registrationData?.Time,
          scheduleId: registrationData?.calendarData[registrationData?.Date][registrationData?.Time + 'ConfigId'],
          properties: {
            EarlierAvailability: earlierAvailability,
          },
        })
          .then((resp) => {
            const workOrderId = resp.data.data[0].id
            navigate(`/appointment-success?woId=${workOrderId}/`);
            gContext.hideFullScreenLoader();
            setLoaderTitle('');
          })
          .catch((error) => {
            gContext.setAPIErrorMsg({
              title: 'Error',
              message: error.response ? error.response?.data.message : error
            });
            gContext.hideFullScreenLoader();
            setLoaderTitle('');
            navigate('/');
          });
      }
    } catch (e) {
      gContext.hideFullScreenLoader();
      setLoaderTitle('');
      console.log(e);
    }
  }

  const isCustomDateSelected = () => {
    if (registrationData?.Date && registrationData?.Time && dateFilter === 'CUSTOM') {
      return true;
    } else {
      return false;
    }
  };

  const onNoSlotsModalHide = () => {
    setShowNoSlotsModal(false)
  }

  const clearCustomDate = (dateFilter) => {
    gContext.goSetRegistrationData({
      ...gContext.goGetRegistrationData(),
      Date: undefined,
      Time: undefined,
      dateFilter,
    });
  };

  return (
    <>
      <FullScreenLoader title={loaderTitle} />
      <Row className="justify-content-center">
        <Col sm={12} md={6} className="mb-3 mt-5 pt-5">
          <div className="checkoutContractBox">
            <span
              style={{ fontWeight: 800, fontSize: '1.3em' }}
              className="d-block text-center mt-2"
            >
              Schedule your installation and get connected to our ultra fast fibre network!
            </span>
            <Row className={`p-4 ${gContext.quoteLoading || gContext.totalLoading ? ' no-slots-available' : ''}`}>
              {/* Go Live - First available data */}
              <Col xs={12} sm={6} className="p-2">
                <div
                  className={`optionButton ${dateFilter === 'EARLIEST' ? 'selected' : ''}`}
                  onClick={() => {
                    setDateFilter('EARLIEST');
                    gContext.goSetRegistrationData({
                      ...gContext.goGetRegistrationData(),
                      Date: firstDate.Date,
                      Time: initialTime,
                      dateFilter: 'EARLIEST',
                    });
                  }}
                >
                  <span style={{ fontWeight: 400 }}>Go Live</span>
                  <br />
                  <span className="mt-2" style={{ fontWeight: 800, fontSize: '1.2em' }}>
                    {initialDate} - {initialTime}
                  </span>
                </div>
              </Col>

              {/* Go Live - Specific date */}
              <Col xs={12} sm={6} className="p-2">
                <div
                  className={`optionButton ${dateFilter === 'CUSTOM' || isCustomDateSelected() ? 'selected' : ''
                    }`}
                  onClick={() => {
                    clearCustomDate('CUSTOM')
                    setDateFilter('CUSTOM');
                  }}
                >
                  <Row className="justify-content-between">
                    <Col xs={12}>
                      <span style={{ fontWeight: 400 }}>Go Live</span>
                      <br />
                      <span
                        style={{ fontWeight: 800, fontSize: isMobile ? '10px' : '1.2em' }}
                        className="mt-2"
                      >
                        {gContext.goGetRegistrationData()?.Date &&
                          gContext.goGetRegistrationData()?.Time &&
                          isCustomDateSelected()
                          ? `${moment(gContext.goGetRegistrationData()?.Date, 'YYYY-MM-DD').format(
                            'DD MMM YYYY',
                          )} - ${gContext.goGetRegistrationData()?.Time}`
                          : 'Later'}
                      </span>
                      <br style={{ minHeight: 20 }} />
                    </Col>
                  </Row>
                </div>
              </Col>

              {
                dateFilter === 'EARLIEST' &&
                <div>
                  <Col xs={12} className={`p-2 mt-4`}>
                    <Row>
                      <Col xs={12}>
                        <span style={{ fontWeight: 800, fontSize: '1.3em' }} className="mt-2">
                          Yay, we can get you as early as {initialDate} - {initialTime}
                        </span>
                      </Col>
                      <Col xs={12} className="mt-3">
                        <span style={{ fontWeight: 400 }}>
                          This is the fastest we can connect you full fibre. But the good news is,
                          your property is all ready for You!
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </div>
              }

              {dateFilter === 'CUSTOM' && !isCustomDateSelected() && (
                <TimeSlotPicker calendarData={calendarData} firstDate={firstDate?.Date} />
              )}

              {/* Custom Date Explanation */}
              {isCustomDateSelected() && (
                <Col xs={12} className={`p-2 mt-4`}>
                  <Row>
                    <Col xs={12}>
                      <span style={{ fontWeight: 800, fontSize: '1.3em' }} className="mt-2">
                        Alright, we can install your service on{' '}
                        {moment(customDate, 'YYYY-MM-DD').format('DD MMM YYYY')} - {customTime}
                      </span>
                    </Col>
                    <Col xs={12} className="mt-3">
                      <div style={{ fontWeight: 400 }}>
                        <span>Don't worry, we've got you! We'll remind you of your installation date in the days leading up to getting You!</span>
                      </div>
                      <div style={{ fontWeight: 400 }} className="mt-3">
                        <span>On the day of your install, our engineers will call you when they are on their way.
                          Once they arrive, they will talk you through the installation and make sure we install in the optimal place for You.</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </div>
        </Col>
        <Col sm={12} lg={12} className="mt-3 pl-0 text-center">
            <TinySwitch
              setValue={(e) => { setEarlierAvailability(e) } }
              value={earlierAvailability}
            />
            <span style={{
              position: 'relative',
              bottom: '5px'
            }}>Please contact me if an earlier appointment is available.</span>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col sm={12} md={4} className="text-center mb-4 mt-4">
          <BigRoundButton
            title="Submit"
            onClick={() => submitAppointment()}
            disabled={(!registrationData?.Date || !registrationData?.Time) && !gContext.goGetRegistrationData()?.noSlotsAvailable || gContext.quoteLoading || gContext.totalLoading}
          />
        </Col>
      </Row>
      <Modal
        show={showNoSlotsModal}
        onHide={() => onNoSlotsModalHide()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: '10px' }}
      >
        <Modal.Body className="p-4">
          <h4 className="text-center">No available slots</h4>
          <p className="text-center">There are no availabile installation slots at the moment.</p>
          <div className="desktop-center">
            <Button
              className="medium-button blue-bg mb-3 mr-3"
              onClick={() => {
                gContext.goResetRegistrationData()
                navigate('/');
              }}
            >
              Go back to the homepage
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AppointmentsPage;
